import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import Tada from 'react-reveal/Tada';
import {imagenes} from '../config/configuraciones'


class Sidebar1 extends React.Component {


  render () {
    // const closeMenu = () => {
    //   document.querySelector(".sidebar").classList.remove("open");
    // };

    return (
      <div className="lista-navbar">
        <Tada>
        <Link to="/">
          <img
            src="../../comodin2/logo.png"
            className="icon-logo"
            alt="Logotipo Club comodín salas. El club de apuestas, máquinas slot tragamonedas y juegos de azar que más paga."
          />
        </Link>
        </Tada>

        <Link to="/mbokaja-juegos" id="juegos" className="lista-sidebar-n" >
          Juegos
        </Link>
        <Link to="/noticias-y-trucos" id="noticias-y-trucos" className="lista-sidebar-n" >
          Noticias
        </Link>
        <Link to="/promociones" id="Promociones" className="lista-sidebar-n">
          Promociones
        </Link>
        <Link to="/tutoriales" id="Tutoriales" className="lista-sidebar-n">
          Tutoriales
        </Link>
        <Link to="/sorteos"id="Sorteos" className="lista-sidebar-n">
          Sorteos
        </Link>
        <Link to="/canjea-tus-puntos" id="canjear" className="lista-sidebar-n">
          Canjear puntos
        </Link>
        <Link to="/contacto"id="contacto" className="lista-sidebar-n">
          Contacto
        </Link>
        <Link to="/socios" id="Socios"  className="lista-sidebar-ns">
          Socios
        </Link>
        
      </div>
   );
  }
}
  
export default Sidebar1;
