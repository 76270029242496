import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Cart from './Cart';
import SolidButton from '../componentes/buttons/SolidButton';
import { Link } from 'react-router-dom';
import {imagenes} from '../config/configuraciones'



// const options = [
//   'None',
//   'Atria',
//   'Callisto',
//   'Dione',
//   'Ganymede',
//   'Hangouts Call',
//   'Luna',
//   'Oberon',
//   'Phobos',
//   'Pyxis',
//   'Sedna',
//   'Titania',
//   'Triton',
//   'Umbriel',
// ];

const ITEM_HEIGHT = 48;

export default function LongMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
                <img
                  src="../../comodin2/carrito.png"
                  className="icono"
                  alt="carrito"
                />
      </IconButton>
      <Menu
        id="menu-cart"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',

          },
        }}
      >
        
          <MenuItem  className="cart-items"onClick={handleClose}>
           <Cart/>

          </MenuItem>
          <div className="canjear-b">
            <Link to="/carrito">
              <SolidButton onClick={handleClose} txt="Ir a canjear"></SolidButton>
            </Link>
            </div>
      
      </Menu>
    </div>
  );
}