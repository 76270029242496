import React from "react";
import SolidButton from "../componentes/buttons/SolidButton";
import Zoom from 'react-reveal/Zoom';
import { Link } from "react-router-dom";
import {imagenes} from '../config/configuraciones'

function Socios() {
  return (
    <div>
      
      <div className="imagen-home">
     <Zoom>
        <img
          loading="lazy"
          src={`${imagenes.urlImage}/coco-2.png`}

          className="juego-piña"
          alt="Buscamos socios para trabajo de calidad en tres formatos."
        />
        </Zoom>
        <div className="col-derecha">
          <img
            loading="lazy"
            src={`${imagenes.urlImage}/trabaja.png`}

            className="juego-t-img"
            alt=" Ofertas de trabajo de representante comercial"
          />
          <img
            loading="lazy"
            src={`${imagenes.urlImage}/hacete.png`}

            className="juego-t-img"
            alt=", alianzas comerciales para dueños de comercios, e influencers."
          />
          <Link to="/socios">
          <SolidButton txt="Registrate"></SolidButton>
          </Link>
          
        </div>
      </div>
     
    </div>
  );
}

export default Socios;
