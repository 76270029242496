import React, { Component } from "react";
import { formatCurrency, priceNivel } from "../componentes/Util";
import { connect } from "react-redux";
import { addToCart, updateCart } from "../action/CartActions";
import { removeFromCart, decrementToCart } from "../action/CartActions";


let nivel = null;
if (localStorage.getItem("userInfo")) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  nivel = userInfo.nivel;
}
const tachito = "../../comodin2/tachito.png"

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    
    
    const config_server = JSON.parse(localStorage.getItem("configServer"));

    const { cartItems } = this.props;

    return (
      <div>
        {cartItems.length === 0 ? (
          <div className="cart-cart-header">
            <p className="color-black">El carrito esta vacío</p>
          </div>
        ) : (
          <div>
            {/* <p> Tienes {cartItems.length} productos en el carrito{" "}</p>
            <br/><br/><br/> */}
          </div>
        )}
        <div>
          <div className="cart-home">
            {cartItems.map((item) => (
              <div key={item.id_producto}>
                <div className="cart-items">
                  <div className="home-img-cart">
                    <img
                      className="imagen-carrito"
                      src={`${config_server.urlProducto}/${item.imagen_producto}`}
                      alt={item.nombre_producto}
                    ></img>
                  </div>
                  <div className="parrafo-carrito">
                    <p className="titulo-cart color-black bold" dangerouslySetInnerHTML={{__html: item.nombre_producto}}/>
                    
                    <p className="color-black bold">
                      {formatCurrency(
                        priceNivel(
                          item.precio_bronce_producto,
                          item.precio_plata_producto,
                          item.precio_oro_producto,
                          item.precio_platino_producto,
                          nivel
                        )
                      )}
                    </p>
                  </div>

                  <div className="cart-price-tachito">
                    <button
                      className="cart-button"
                      onClick={() => this.props.removeFromCart(item)}
                    >
                      <img
                        src={tachito}
                        className="tachito"
                        alt="eliminar"
                      />
                    </button>
                  </div>
                  <div className="qty">
                    <button
                      className="cart-button"
                      onClick={() => this.props.decrementToCart(item,nivel)}
                    >
                      <h2 className="color-black">-</h2>
                    </button>
                    <b className="show-qty">{item.count}</b>

                    <button
                      className="cart-button"
                      onClick={() => this.props.addToCart(item,nivel)}
                    >
                      <h2 className="color-black">+</h2>
                    </button>
                  </div>
                </div>
                <hr className="hr" />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    cartItems: state.cart.cartItems,
  }),
  { removeFromCart, addToCart, decrementToCart, nivel,updateCart }
)(Cart);
