import React from "react";
import "../App.css";
import { connect } from "react-redux";
import { removeFromCart } from "../action/CartActions";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Sidebar1 from "./Sidebar1";
import BotonCart from "./BotonCart";
import BotonUser from "../userBotones/BotonUser";
import Tada from 'react-reveal/Tada';
import {imagenes} from '../config/configuraciones'


class Navigation extends React.Component {
  render() {
    const openMenu = () => {
      document.querySelector(".sidebar").classList.add("open");
    };

    const { cartItems } = this.props;

    return (
      <div className="navigation">
        <nav className="nav1">
          <div className="contenedor-nav">
            
              <button className="icono-button" onClick={openMenu}>
                <img
                  src="../../comodin2/icon-menu.png"
                  className="icono"
                  alt="menu"
                />
              </button>
              
            
            <Tada>
              <Link to="/">
                <img
                  src="../../comodin2/logo.png"
                  className="icon-logo"
                  alt="Logotipo Club comodín salas. El club de apuestas, máquinas slot tragamonedas y juegos de azar que más paga."
                />
              </Link>
              </Tada>
            <div className="logo-nav"><BotonUser/></div>
            <div className="logo-nav-2">
            <div className="icono-cart">
                {cartItems.length === 0 ? (
                  <div >

                    <span className="cart-header">0</span>
                    <BotonCart/>
                  </div>
                ) : (
                  <div >

<span className="cart-header">{cartItems.length}</span>
                    <BotonCart/>
                  </div>
                )}
              </div>
            

            </div>
          </div>
        </nav>

        <div className="sidebar">
          
          <Sidebar />
        </div>
        <nav className="nav2">
          <div className="contenedor-nav">


            <Sidebar1/>
            <div className="logo-nav"><BotonUser/></div>
            <div className="icono-cart">
                {cartItems.length === 0 ? (
                  <div >

                    <span className="cart-header">0</span>
                    <BotonCart/>
                  </div>
                ) : (
                  <div >

<span className="cart-header">{cartItems.length}</span>
                    <BotonCart/>
                  </div>
                )}
              </div>
          </div>
        </nav>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    cartItems: state.cart.cartItems,
  }),
  { removeFromCart }
)(Navigation);
