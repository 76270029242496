import React from "react";
import Flash from 'react-reveal/Flash';
import {imagenes} from '../config/configuraciones'

function Header() {
  return (
    
    <div className="header-home">
      <Flash>
      <img
        
        src={`${imagenes.urlImage}/banner-celu.png`}
        className="header-img-cel"
        alt="Imagen de jackpot comodin ¡Con Club comodín salas salas ganás o ganás! "
      />
      <img
        
        src={`${imagenes.urlImage}/banner.png`}
        className="header-img-compu"
        alt="Imagen de jackpot comodin ¡Con Club comodín salas salas ganás o ganás! "
      />
      </Flash>
    </div>
    
  );
}

export default Header;
