// Import css files
import React from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import SolidButton from "../componentes/buttons/SolidButton";
import Card from "react-bootstrap/Card";
import { addToCart } from "../action/CartActions";
import useInitiaState from "../hooks/useInitiaState";
import { Link } from "react-router-dom";
import serverComodin from "../config/serverComodin";
import { priceNivel } from "../componentes/Util";

const API = serverComodin.baseURL + "/product/st/destacados";

function ProductosP() {
  // const [num, setNum] = useState("");
  // const [nivel, setNivel] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  let nivel = null;
  if (userInfo) {
    nivel = userInfo.nivel;
  }
  const productos = useInitiaState(API);
  const config_server = JSON.parse(localStorage.getItem("configServer"));

  const dispatch = useDispatch();

  const config = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    arrows: false,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 4500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 1500,
          autoplaySpeed: 4500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 1500,
          autoplaySpeed: 4500,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 1500,
          autoplaySpeed: 4500,
        },
      },
    ],
  };

  const settings = config;
  // console.log("products", products);
  return (
    <div className="imagen-home-promociones">
      <h1>Productos</h1>
      <h2>¡Todas las jugadas suman puntos!</h2>

      <br />
      <br />

      {!productos ? (
        <div className="imagen-home-promociones1-oculto"></div>
      ) : (
        <Slider {...settings} aria-hidden="true">
          {productos.map((product) => {
            return (
              <div className="card-2" key={product.id_producto}>
                <Card className="card-1">
                  <Card.Body>
                    <div className="card-container">
                      <Link
                        className="link-name"
                        to={`/products/${product.id_producto}/${(product.nombre_producto).replace(/ /g, '-')}`}
                      >
                        <img
                          src={`${config_server.urlProducto}/${product.imagen_producto}`}
                          className="products-carousel"
                          alt="Producto, premios y beneficios del Club comodín salas. "
                        />
                      </Link>
                    </div>
                    <Card.Title>
                      
                      <h2  className="centrar color-black card-title" dangerouslySetInnerHTML={{__html: product.nombre_producto}} />
                    </Card.Title>
                    {userInfo ? (
                      
                        <div className="price-user1">
                          <div className="centrar nivel-user">
                            {userInfo.nivel}:
                          </div>
                          {priceNivel(
                            product.precio_bronce_producto,
                            product.precio_plata_producto,
                            product.precio_oro_producto,
                            product.precio_platino_producto,
                            userInfo.nivel
                          )}{" "}
                          Pts
                        </div>
                      
                    ) : (
                      <Card.Text>
                        Bronce: {product.precio_bronce_producto} Pts
                        <br />
                        Plata: {product.precio_plata_producto} Pts
                        <br />
                        Oro: {product.precio_oro_producto} Pts
                        <br />
                        Platino: {product.precio_platino_producto} Pts
                        <br />
                      </Card.Text>
                    )}
                  </Card.Body>

                  <div className="botones-productos">
                    <SolidButton
                    type="agregar-productos-p"
                      onClick={() => dispatch(addToCart(product,nivel))}
                      txt="Agregar al carrito"
                    ></SolidButton>
                    {config_server.botonComprarAhora === true ? (
                      <div>
                        {userInfo ? (
                          <Link to="/carrito">
                            <SolidButton
                              type="amarillo-compra-productos-p"
                              onClick={() => dispatch(addToCart(product,nivel))}
                              txt="Canjear"
                            ></SolidButton>
                          </Link>
                        ) : (
                          <Link to="/signin">
                            <SolidButton
                              type="amarillo-compra-productos-p"
                              onClick={() => dispatch(addToCart(product,nivel))}
                              txt="Canjear"
                            ></SolidButton>
                          </Link>
                        )}
                      </div>
                    ) : (
                      <br />
                    )}
                  </div>
                </Card>
              </div>
            );
          })}
        </Slider>
      )}

      <div className="botones">
        <Link to="/canjea-tus-puntos">
          <SolidButton txt="Ver todo"></SolidButton>
        </Link>
      </div>
    </div>
  );
}

export default ProductosP;
