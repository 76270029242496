import React from "react";
import '../App.css';
import { Link } from "react-router-dom";




class Sidebar2 extends React.Component {

  
    render () {
      const closeMenu = () => {
        document.querySelector(".sidebar").classList.remove("open");
      };

      return (
        
        <div className="lista">
                  
        
          <Link  onClick={closeMenu} to="/"id="home" className="lista-sidebar">Inicio</Link>
          <Link  onClick={closeMenu} to="/mbokaja-juegos" id="Juegos" className="lista-sidebar">Juegos</Link>
          <Link  onClick={closeMenu} to="/noticias-y-trucos" id="noticias-y-trucos" className="lista-sidebar">Noticias y trucos</Link>
          <Link  onClick={closeMenu} to="/promociones" id="Promociones" className="lista-sidebar">Promociones</Link>
          <Link  onClick={closeMenu} to="/tutoriales" id="Tutoriales" className="lista-sidebar">Tutoriales</Link>
          <Link  onClick={closeMenu} to="/sorteos"id="Sorteos" className="lista-sidebar">Sorteos</Link>
          <Link  onClick={closeMenu} to="/canjea-tus-puntos" id="canjear" className="lista-sidebar">Canjear puntos</Link>
          <Link  onClick={closeMenu} to="/contacto" id="Contacto" className="lista-sidebar">Contacto</Link>
          <Link  onClick={closeMenu} to="/socios" id="Socios" className="lista-sidebar">Socios</Link>
        

        
        </div>
      );
    }
  }
  export default Sidebar2;